@import url("https://use.typekit.net/koe2crz.css");
@import "~antd/dist/antd.less";
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: KuranFont;
  src: url("./font/ShaikhHamdullahMushaf.ttf");
}

@font-face {
  font-family: Bookerly;
  src: url("./font/Bookerly-Regular.ttf");
}
@font-face {
  font-family: Bookerly;
  src: url("./font/Bookerly-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Bookerly;
  src: url("./font/Bookerly-RegularItalic.ttf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Bookerly;
  src: url("./font/Bookerly-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}
.bg404 {
  background-image: url("./assets/images/404.jpg");
}
.anticon svg {
  display: block;
}
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 18px !important;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid #ddd;
  outline: 0;
  border-radius: 4px;
  background: white;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: 0em;
}
.login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.main-menum.ant-menu-horizontal {
  justify-content: center !important;
  line-height: 50px !important;
  margin-left: -15px;
  margin-right: -15px;
  // background-color: rgba(0, 0, 0, 0.1) !important;
  // background-color: #854516 !important;
  //box-shadow: 0 0 5px #ddd;
  background-color: transparent;
}
.main-layout {
  overflow: scroll !important;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-layout-content {
  background-color: white !important;
  min-height: calc(100vh - 188px);
}
.ant-layout-header {
  height: unset !important;
  background-color: white;
  padding: 0 15px !important;
}
.desktop-sider {
  margin-top: 64px;
  background-color: #fafafa;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-menu-item-selected {
  font-weight: 700;
  background-color: white !important;
}
.amenu {
  margin-top: 10px;
}
.amenu .ant-menu-item-selected {
  background-color: white !important;
  border-top: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}
.ant-drawer-body {
  padding: 0;
}
.ant-menu-horizontal {
  border-bottom: none;
}
.desktop-sider {
  margin-top: 64px;
  background-color: #fafafa;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-layout-content {
  background-color: #fff !important;
}
.footer {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  background-color: #fafafa;
  padding: 15px 0 15px 0;
}
.signatureCanvas {
  border: 1px solid #ddd;
  width: 100%;
  height: 300px;
}
.nested .ant-table table {
  background-color: #f0f9ff !important;
}
.nested .ant-table-thead > tr > th {
  background-color: #e0f2fe !important;
  border-bottom: 1px solid #ccc;
  color: #0891b2 !important;
  font-weight: 600;
}
.ant-descriptions-item-content {
  color: #106ee9;
  font-size: 14px;
}
.ant-tag {
  font-size: 14px;
}
.site-tag-plus {
  cursor: pointer;
  background: #fff;
  color: #108ee9;
  border-style: dashed;
}
.edit-tag {
  user-select: none;
}
.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
}
.ant-layout {
  background-color: #fff;
}
.ayet-dot-img {
  width: 26px;
  margin-left: 3px;
  margin-right: 3px;
  display: inline !important;
}
.aramaList {
  border: 1px solid #ddd;
}
.aramaList .ant-list-item {
  background-color: #feeeb6;
  border-color: #e5d8ab;
}
.aramaList .ant-list-header {
  background-color: white;
  color: crimson;
}
.aramaList .ant-list-pagination {
  margin: 10px 15px 0 15px;
}
.aramaList .ant-list-item-meta-title {
  color: green;
}
.highlight-class {
  padding: 0;
  margin: 0;
  font-family: "Bookerly";
  color: #c4640a;
  background-color: unset;
}
.ant-btn-icon-only.ozel {
  background-color: #f1d78d;
  border-color: #dac68a;
  color: #796c47;
}
p.arapca {
  font-size: 24px;
  font-family: "KuranFont" !important;
}
p.meal {
  font-size: 15px;
  font-family: "Bookerly" !important;
}
.ant-btn.ozel {
  background-color: #f1d78d;
  border-color: #dac68a;
  color: #796c47;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.12);
}
.ant-card {
  border: 1px solid #ddd;
}
.fullscreen-enabled {
  overflow: auto;
  background-color: #4b4b4b;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fafafa;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  font-weight: 500;
}
.ant-menu .ant-menu-sub {
  background-color: #f0f0f0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.ant-menu .ant-menu-sub .ant-menu-item-selected {
  background-color: #fafafa;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  font-weight: 500;
}
.site-layout {
  background-color: #fafafa !important;
  border-left: 1px solid #ddd;
  font-size: 15px;
  height: calc(100vh -64px);
}

.page-layout {
  /* margin: 74px 16px; */
  padding: 16px 16px;
  background-color: #fafafa !important;
  display: inline-table;
}
.ant-list-header {
  border-bottom: 1px solid #ddd !important;
}

.trigger {
  font-size: 26px;
  line-height: 64px;
  padding: 3px 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.logo {
  /* background-image: url("./images/logo.png");
  background-repeat: no-repeat;
  background-size: cover; */
  font-family: "Bookerly";
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  height: 40px;
  color: #e7ad0d;
  background-color: white;
  height: 64px;
  padding-top: 15px;
  border-bottom: 1px solid #ddd;
  text-shadow: 0px 0px 1px rgba(50, 50, 50, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
.arapca-border {
  border: 10px solid green;
  border-image-source: linear-gradient(#988f6d, #cbbe92);
  border-image-slice: 10;
  margin: -12px;
  padding: 15px;
}
.arapca-ic-border {
  border: 3px solid white;
  margin: -15px;
  padding: 15px;
  box-shadow: 0 0 5px #333;
}
.kart2 .ant-card-body {
  padding: 0 !important;
  /* background-color: #fff3b7; */
  background-color: #fff4ce;
  border-top: 1px solid #ddd;
}
.kart3 .ant-card-body {
  /* background-color: #faf2ca; */
  border-top: 1px solid #ddd;
  background-color: #feeeb6;
}

.bejbg {
  background-color: #feeeb6;
}
.ant-list-item.bejbg {
  border-bottom: 1px solid #e5d8ab;
  padding: 8px 14px;
}
.sure-bg {
  background-image: url("./images/bg-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 5px;
  height: 75px;
  border: 1px solid #777;
  margin: 0 -16px;
}
div.ant-layout-sider-children {
  height: calc(100vh);
}
.ant-input-search-enter-button + .ant-input-group-addon,
.ant-input-search-enter-button input + .ant-input-group-addon {
  padding: 2px;
  border: 1px solid #ddd;
}
.ant-input-group-addon:first-child,
.ant-input-search-enter-button
  + .ant-input-group-addon
  .ant-input-search-button {
  background-color: #f1d78d;
  border-color: #dac68a;
  color: #796c47;
}
.container {
  border: 1px solid #ddd;
  padding: 15px;
  background-color: #f5f5f5;
}
.ant-layout.ant-layout-has-sider {
  background-color: #eeeeee !important;
}

@primary-color: #0EA5E9;